<template>
  <el-main>
    <el-button style="margin-bottom:10px;" size="small" type="primary" icon="el-icon-plus" @click="addType">添加分类</el-button>
    <el-form class="el-form-search" label-width="100px">
      <el-form-item label="文章分类：">
        <el-input size="small" v-model="name" placeholder="请输入分类名称"></el-input>
      </el-form-item>
      <el-form-item label=" " label-width="25px">
        <el-button size="small" type="primary" @click="getTypeList()">搜索</el-button>
        <el-button size="small" type="text" @click="clearSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }">
      <template slot="empty">
        <No />
      </template>
      <el-table-column prop="type" align="center" width="100">
        <template #header>
          <div class="rank">
            <span>排序</span>
            <i class="el-icon-edit-outline"></i>
          </div>
        </template>
        <template slot-scope="scope">
          <el-input v-model="scope.row.rank" @change="tableChange(scope.row)"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="classify_name" label="分类名称" align="center"></el-table-column>
      <el-table-column prop="update_time" label="修改时间" align="center">
        <template slot-scope="scope">
          <span>{{ getDateformat(scope.row.update_time) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="is_show" label="是否显示" align="center">
        <template slot-scope="scope">
          <el-switch v-model="scope.row.is_show" :active-value="1" :inactive-value="0" @change="tableChange(scope.row)"></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="200" align="center">
        <template slot-scope="scope">
          <el-button @click="edit(scope.row)" type="text" size="small">编辑</el-button>
          <el-button @click="remove(scope.row.id, scope.$index)" type="text" size="small">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Paging :total="total" :page="page" :pageNum="pageNum" @updatePageNum="updateData"></Paging>
    <el-dialog :title="type ? '编辑分类' : '添加分类'" :visible.sync="showAddType" width="30%" :before-close="handleClose">
      <el-form ref="form" class="addType" :model="addForm" :rules="rules" label-width="100px">
        <el-form-item label="分类排序：" prop="rank">
          <el-input v-model="addForm.rank"></el-input>
        </el-form-item>
        <el-form-item label="分类名称：" prop="classify_name">
          <el-input v-model="addForm.classify_name"></el-input>
        </el-form-item>
        <el-form-item label="分类图片：">
          <ReadyUploadSource
            :showStyle="{
              width: '220px',
              height: '110px',
            }"
            :path="addForm.classify_picture"
            @getSource="getImg"
            @removeThis="removeImg"
          ></ReadyUploadSource>
        </el-form-item>
        <el-form-item label="是否显示：">
          <el-switch v-model="addForm.is_show" :active-value="1" :inactive-value="0"></el-switch>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showAddType = !1">取 消</el-button>
        <el-button type="primary" @click="addTypeSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </el-main>
</template>

<script>
import Paging from '../../components/paging.vue';
import { getDateformat } from '../../util/getDate';
import { matchDataAssign } from '@/util/util';
import ReadyUploadSource from '@/components/readyUploadSource';
export default {
  components: {
    Paging,
    ReadyUploadSource,
  },
  data() {
    return {
      showAddType: !1,
      type: 0,
      list: [],
      name: '',
      id: '',
      page: 1,
      pageNum: 10,
      total: 0,
      addForm: {
        rank: '',
        classify_name: '',
        classify_picture: '',
        is_show: 1,
      },
      rules: {
        rank: [
          {
            required: true,
            message: '请输入分类排序',
            trigger: 'blur',
          },
        ],
        classify_name: [
          {
            required: true,
            message: '请输入分类名称',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  created() {
    this.getTypeList();
  },
  methods: {
    clearSearch() {
      this.name = '';
      this.getTypeList();
    },
    addType() {
      this.showAddType = !0;
      this.addForm = {
        rank: '',
        classify_name: '',
        classify_picture: '',
        is_show: 1,
      };
    },
    addTypeSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          let url = this.type ? this.$api.set.editArticleClass : this.$api.set.addArticleClass;
          let data = {
            rank: this.addForm.rank,
            classify_name: this.addForm.classify_name,
            classify_picture: this.addForm.classify_picture,
            is_show: this.addForm.is_show,
          };
          if (this.type) data.id = this.id;
          this.$axios.post(url, data).then(res => {
            if (res.code == 0) {
              this.showAddType = !1;
              this.getTypeList();
              this.$message({
                message: `${this.type ? '编辑成功' : '添加成功'}`,
                type: 'success',
              });
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      });
    },
    getTypeList() {
      this.$axios
        .post(this.$api.set.listArticleClass, {
          page: this.page,
          rows: this.pageNum,
          classify_name: this.name,
        })
        .then(res => {
          if (res.code == 0) {
            let list = res.result.list;
            this.total = res.result.total_number;
            this.list = list;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    getImg(val) {
      this.addForm.classify_picture = val.path;
    },
    removeImg() {
      this.addForm.classify_picture = '';
    },
    updateData(val, status) {
      if (status == 0) {
        this.pageNum = val;
        this.getTypeList();
      } else {
        this.page = val;
        this.getTypeList();
      }
    },
    edit(row) {
      this.type = 1;
      this.showAddType = !0;
      this.addFrom = matchDataAssign(this.addForm, row);
      this.id = row.id;
    },
    remove(id, index) {
      this.$confirm('确认删除此分类？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$axios
          .post(this.$api.set.delArticleClass, {
            id: id,
          })
          .then(res => {
            if (res.code == 0) {
              this.$message({
                message: '删除成功',
                type: 'success',
              });
              this.list.splice(index, 1);
            } else {
              this.$message.error(res.msg);
            }
          });
      });
    },
    tableChange(row) {
      this.type = 1;
      this.id = row.id;
      this.addFrom = matchDataAssign(this.addForm, row);
      this.addTypeSubmit();
    },
    getDateformat(val) {
      return getDateformat(val);
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
  .el-table {
    border-left: 1px solid #e8eef1;
    border-right: 1px solid #e8eef1;
    /deep/ .el-input__inner {
      border-color: #fff;
    }
    /deep/ .el-input__inner:focus {
      border-color: #409eff;
    }
  }
}
</style>
